/* Report Selector and Post-Login Landing Page. */
import * as React from 'react';
import { Box, Button, Grid, InputLabel, FormControl, Select, MenuItem, Typography } from "@mui/material";
import AutoModeIcon from '@mui/icons-material/AutoMode';
import DownloadIcon from '@mui/icons-material/Download';
import LoadingButton from '@mui/lab/LoadingButton';
import { api_client } from '../auth_api';
import { fetchToken } from '../auth';
import { getCountyNames, getProjectNames } from '../api/fields';

export default function ReportSelection() {
    const [county, setCounty] = React.useState("");
    const [counties, setCounties] = React.useState([]);
    const [project, setProject] = React.useState("");
    const [allProjects, setAllProjects] = React.useState([]);
    const [generating, setGenerating] = React.useState(false);
    const [available, setAvailable] = React.useState(false);
    const [year, setYear] = React.useState(window.years[window.years.length-1]);

    const delay = ms => new Promise(
      resolve => setTimeout(resolve, ms)
    );

    React.useEffect(()=>{
      // Load Requisites when page Completes
      getCountyNames({
        onLoad: setCounties,
        onLoadDefault: setCounty,
      });
      getProjectNames({
        onLoad: setAllProjects,
      })
    },[]);

    const handleCountyChange = (event) => {
      setCounty(event.target.value);
    };

    const handleYearChange = (event) => {
      setYear(event.target.value);
    }

    const handleProjectChange = (event) => {
      setProject(event.target.value);
      setAvailable(false);
    };

    const checkReportCompletionStatus = () => {
      // Check Status of Report Generation
      api_client.get("total-expense/check",
      {
        withCredentials: true,
        params: {
          project_name: project,
        },
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${fetchToken()}`,
        },
      }).then(res => res.data).then(jsonData => {
        if (jsonData) {
          setAvailable(true);
          setGenerating(false);
        } else {
          delay(750).then(_ => {
            checkReportCompletionStatus();
          });
        }
      })
      .catch((error) => {
        if( error.response ){
          console.log(error.response.data); // => the response payload
        }
      });
    }

    const handleReportGenerationRequest = () => {
      setGenerating(true);
      // List all Projects
      api_client.post("total-expense/generate",
      {
        county_id: counties.filter((c) => c.name === county)[0].id,
        project_name: project,
        year: year,
      },
      {
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${fetchToken()}`,
        },
      }).then(_ => {
        checkReportCompletionStatus();
      })
      .catch((error) => {
        if( error.response ){
          console.log(error.response.data); // => the response payload
        }
      });
    }

    const downloadReport = () => {
      const link = document.createElement("a");
      link.download = `${project}.csv`;
      link.href = (
        `/api/v1/total-expense/download/${project.replace("/", "--")}` +
        `?token=${fetchToken()}&client_token=${window.token}`
      );
      link.click();
    }

  return (
    <Box m={2} sx={{ flexGrow: 1 }}>
      <Grid
        container
        rowSpacing={1}
        direction="column"
        alignItems="center"
        columnSpacing={{ xs: 1, sm: 2 }}
      >
        <Grid item sm={4} xs={12}>
          <Box sx={{ minWidth: 460 }}>
            <FormControl fullWidth>
              <InputLabel id="county-selection">County</InputLabel>
              <Select
                labelId="county-selection-label"
                id="county-selection-select"
                value={county}
                label="County"
                onChange={handleCountyChange}
              >
                {counties.map((county_data) => (
                  <MenuItem value={county_data.name}>
                    {county_data.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <br />
          <Box sx={{ minWidth: 460 }}>
            <FormControl fullWidth>
              <InputLabel id="year-selection">Year</InputLabel>
              <Select
                labelId="year-selection-label"
                id="year-selection-select"
                value={year}
                label="Year"
                onChange={handleYearChange}
              >
                {window.years.map((year_opt) => (
                  <MenuItem value={year_opt}>{year_opt}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <br />
          <Box sx={{ minWidth: 460 }}>
            <FormControl fullWidth>
              <InputLabel id="project-selection">Project</InputLabel>
              <Select
                labelId="project-selection-label"
                id="project-selection-select"
                value={project}
                label="Project"
                onChange={handleProjectChange}
              >
                {allProjects.map((project_name) => (
                  <MenuItem value={project_name}>{project_name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ minWidth: 460 }}>
            <LoadingButton
              fullWidth
              onClick={handleReportGenerationRequest}
              endIcon={<AutoModeIcon />}
              variant="contained"
              loadingPosition="end"
              loading={generating}
              sx={{ mt: 3, mb: 2 }}
            >
              Generate Report
            </LoadingButton>
          </Box>
          <Box sx={{ minWidth: 460 }}>
            <Button
              fullWidth
              disabled={!available}
              onClick={downloadReport}
              endIcon={<DownloadIcon />}
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Download Report
            </Button>
          </Box>
        </Grid>
        <br />
        <Box sx={{ maxWidth: 600 }}>
          <Typography variant="body1">
            Use this custom report to generate a CSV (Excel-like) file
            with expense totals for each youth participant in a specific
            4-H project. Generating the report may take a few moments, so
            please be patient.
          </Typography>
        </Box>
        <br />
        <Box sx={{ maxWidth: 600 }}>
          <Typography variant="body1">
            After the report has been generated, it the "Download Report"
            button above will enable and become clickable. Click to
            download the report after it's been generated. If you change
            project type, you will need to re-generate the report each
            time, even if you've already generated the report.
          </Typography>
        </Box>
        <br />
        <Box sx={{ maxWidth: 600 }}>
          <Typography variant="body1">
            You can select different counties to run the report for, but
            the report generated will always reflect your access permissions
            in ZSuite. That is, if you are a county admin in "County A" you
            will see all youth records for the county; however, if you are
            only a club leader in "County B", you will only see records of
            youth in the club for which you're a leader.
          </Typography>
        </Box>
      </Grid>
    </Box>
  );
}
